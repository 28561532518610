import { Entities } from '@getvim/vim-connect';
import React from 'react';
import { PriorAuthQueryPayload, Tab } from '../../types';
import { CurrentContext } from '../../types/current-context';
import { PriorAuthInquiryProps } from '../prior-auth-inquiry';
import { componentByInsurer } from './componentByInsurer';

export type ContentProps = {
  currentTab: Tab;
  launchedComponents: { [key: string]: boolean };
  displaySelectProviderScreen: boolean;
  confirmSelectedProvider: (selectedProvider: Entities.Provider) => void;
  selectedProvider: Entities.Provider | undefined;
  setSelectedProvider: (SelectOption) => void;
  isEligibilityFailed: boolean;
  updateEligibility: (context: CurrentContext) => Promise<CurrentContext | undefined>;
  currentContext: CurrentContext;
  goToTab: (tab: Tab) => void;
  updateLaunchedComponents: (tab: { [key: string]: boolean }) => void;
  shouldRelaunch?: boolean;
  changeTabWidth: (tabWidth: string) => void;
  queryPayload?: PriorAuthQueryPayload;
} & PriorAuthInquiryProps;

const Content: React.FC<ContentProps> = (props) => {
  const { launchedComponents, ...tabProps } = props;
  const { currentContext } = props;
  const insurer = currentContext.patient?.insurance?.insurer;
  const tabs =
    insurer && componentByInsurer[insurer]
      ? Object.keys(componentByInsurer[insurer]).map((item) => {
          const TabComponent = componentByInsurer[insurer][item];
          return (
            <TabComponent
              key={item}
              {...tabProps}
              shouldRelaunch={!launchedComponents[item]}
              tab={item}
            />
          );
        })
      : [];

  return <>{tabs}</>;
};

export default Content;
