import React, { FC, useMemo } from 'react';
import { Button } from '@getvim/atomic-ui';
import './priorAuthResult.less';
import {
  DecisionCode,
  PriorAuthResult as PriorAuthResultInterface,
  RedirectOrigin,
  Tab,
} from '../../../types';
import Procedure from './Procedure';
import Disclaimer from './Disclaimer';
import PriorAuthResultError from './PriorAuthResultError';
import { analyticsClient } from '../../../analytics/analytics';

export interface PriorAuthResultProps {
  priorAuthResult?: PriorAuthResultInterface;
  clearPriorAuthResult: () => void;
  useTabs: boolean;
  submitPriorAuth: () => void;
}

const PriorAuthResult: FC<PriorAuthResultProps> = ({
  priorAuthResult,
  clearPriorAuthResult,
  useTabs,
  submitPriorAuth,
}: PriorAuthResultProps) => {
  const { priorAuthData, error } = priorAuthResult || {};

  const isSubmitEnabled = useMemo((): boolean => {
    if (!useTabs) {
      return false;
    }

    return !!priorAuthData?.procedures?.some((procedure) =>
      procedure?.sitesOfService?.some(
        (siteOfService) =>
          siteOfService.code === DecisionCode.Required ||
          siteOfService.code === DecisionCode.Conditional,
      ),
    );
  }, [useTabs, priorAuthData]);

  return error || !priorAuthData ? (
    <PriorAuthResultError error={error} onBackButtonClick={clearPriorAuthResult} />
  ) : (
    <div className="prior-auth-result">
      <Button
        className="back-btn"
        buttonType="tiny"
        bgColor="themedOutline"
        onClick={clearPriorAuthResult}
      >
        <i className="icon-chevron-left" />
        Back
      </Button>

      {priorAuthData?.procedures.map((procedure) => (
        <Procedure
          key={procedure.code}
          procedure={procedure}
          count={priorAuthData.procedures.length}
          state={priorAuthData.state}
          productType={priorAuthData.productType}
        />
      ))}

      <Disclaimer disclaimer={priorAuthData.disclaimer} />

      {useTabs ? (
        <Button
          className="submit-btn"
          buttonType="small"
          bgColor="default"
          text="Submit Prior Auth"
          disabled={!isSubmitEnabled}
          onClick={() => {
            submitPriorAuth();
            analyticsClient.track('prior_auth_tab_switch', {
              tabName: Tab.SubmitCase,
              redirected_from: RedirectOrigin.PA_SUBMIT_BUTTON,
            });
          }}
        />
      ) : (
        <Button
          className="back-btn"
          buttonType="tiny"
          bgColor="themedOutline"
          onClick={clearPriorAuthResult}
        >
          <i className="icon-chevron-left" />
          Back
        </Button>
      )}
    </div>
  );
};

export default PriorAuthResult;
