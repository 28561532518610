import { useEffect } from 'react';
import { VimHubActions } from '@getvim/vim-connect-communication';
import { ActionButtonAction } from '@getvim/utils-vim-connect-communication';
import { Tab } from '../types';

export function useCtaEvent(widgetId: string, goToTab: (tab: Tab) => void, submitPriorAuth): void {
  useEffect(() => {
    const handleEvent = (e: { data: { type: string; payload: any } }) => {
      if (e.data.payload.widgetId !== widgetId) return;
      const buttonAction = e.data.payload.action;
      switch (buttonAction) {
        case ActionButtonAction.SUBMIT_CASE: {
          submitPriorAuth();
          VimHubActions.Actions.openApp({ widgetId });
          break;
        }
        case ActionButtonAction.SEE_MORE_DETAILS: {
          VimHubActions.Actions.openApp({ widgetId });
          goToTab(Tab.PriorAuthInquiry);
          break;
        }
        case ActionButtonAction.EXTERNAL_LINK: {
          VimHubActions.Actions.openApp({ widgetId });
          // https://bookmd.atlassian.net/browse/SAM-39695 - TODO: fix auto popup blocked in chrome by making it a link in the notification
          goToTab(Tab.PriorAuthInquiry);
          window.open('https://www.uhcprovider.com/', '_blank');
          break;
        }
        // No default
      }
    };

    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [goToTab, submitPriorAuth, widgetId]);
}
