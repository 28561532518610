import React, { FC, useState } from 'react';
import { Text, ColorNameEnum, Button, AlignEnum } from '@getvim/atomic-ui';
import './disclaimer.less';

export interface DisclaimerProps {
  disclaimer: string;
}

const Disclaimer: FC<DisclaimerProps> = ({ disclaimer }: DisclaimerProps) => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState<boolean>(false);

  const toggleDisclaimerStyle = () => {
    setIsDisclaimerOpen((isOpen) => !isOpen);
  };

  return (
    <div className="disclaimer">
      <Text className="disclaimer-text" colorName={ColorNameEnum.ultraDark} align={AlignEnum.left}>
        <div className={isDisclaimerOpen ? 'opened' : 'closed'}>{disclaimer}</div>
      </Text>
      <Button
        buttonType="link"
        className="text-underline text-strong text-11 margin-top text-extra-dark"
        onClick={toggleDisclaimerStyle}
        text={isDisclaimerOpen ? 'See less' : 'See more'}
      />
    </div>
  );
};

export default Disclaimer;
