import { Insurer } from '@getvim-os/standard';
import { Entities } from '@getvim/vim-connect';

export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface PriorAuthQueryPayload {
  procedures: SelectOption[];
  diagnosis?: SelectOption;
  insurer?: Insurer;
}

export interface SiteOfService {
  name: string;
  decision: string;
  code: DecisionCode;
  conditionsList?: string[];
}

export interface Procedure {
  code: string;
  description: string;
  decisionSummary: string;
  sitesOfService: SiteOfService[];
}

export enum DecisionCode {
  Required = 1,
  NotRequired = 2,
  Conditional = 3,
  Blocked = 4,
}

export interface PriorAuthData {
  procedures: Procedure[];
  diagnosis?: Entities.Diagnosis;
  productType: string;
  state: string;
  disclaimer: string;
}

export interface PriorAuthResult {
  priorAuthData?: PriorAuthData;
  error?: Error;
}
