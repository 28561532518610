import React, { FC, useEffect, useState } from 'react';
import { Entities } from '@getvim/vim-connect';
import { Team } from '@getvim/feature-flags';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Button } from '@getvim/atomic-ui';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import SearchProviders from './searchProviders';
import { Tab } from '../../types';
import './selectProvider.less';

export interface SelectProviderProps {
  onConfirm: (selectedProvider: Entities.Provider) => void;
  setSelectedProvider: (SelectOption) => void;
  selectedProvider?: Entities.Provider;
  isLoading: boolean;
  currentTab: Tab;
}

const SelectProvider: FC<SelectProviderProps> = ({
  onConfirm,
  setSelectedProvider,
  selectedProvider,
  isLoading,
  currentTab,
}: SelectProviderProps) => {
  const confirmButton = (
    <Button
      disabled={!selectedProvider}
      text="Confirm"
      buttonType="small"
      width="block"
      onClick={() => {
        selectedProvider && onConfirm(selectedProvider);
      }}
      className="select-provider__confirm-btn"
      isLoading={isLoading}
    />
  );

  return (
    <div className="select-provider">
      <SearchProviders
        isDisabled={isLoading}
        setSelectedProvider={setSelectedProvider}
        selectedProvider={selectedProvider}
        currentTab={currentTab}
      />
      {!selectedProvider ? (
        <OverlayTrigger
          overlay={
            <Popover id="select-provider" className="select-provider-tooltip">
              Please select the submitting provider
            </Popover>
          }
        >
          <div>{confirmButton}</div>
        </OverlayTrigger>
      ) : (
        confirmButton
      )}
    </div>
  );
};

export default SelectProvider;
