import {
  ApplicationTitle,
  ButtonGroupComponent,
  Loader,
  NewFeatureTooltip,
  PlacementEnum,
  Toast,
} from '@getvim/atomic-ui';
import '@getvim/atomic-ui/assets/styles/main.less';
import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import {
  registerVimConnectUIWidget,
  sendEventToAppVimConnectUIWidget,
  sendSyncEventToVimConnectHubApp,
} from '@getvim/utils-vim-connect-communication';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Entities, Infra, RuntimeContract, Standard } from '@getvim/vim-connect';
import { parse } from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  analyticsClient,
  getContentSupplierId,
  PriorAuthQuery as PriorAuthAnalyticsQuery,
} from './analytics/analytics';
import './App.less';
import Content from './components/content/Content';
import { optionalTabs } from './components/content/tabs';
import { widgetId } from './consts';
import { WIDGET_DEFAULT_HEIGHT } from './consts/widgetSize';
import { eligibilityLogger, priorAuthLogger } from './logger';
import {
  FreeTextQueryPayload,
  FreeTextQueryResult,
  FreeTextResult,
  FreeTextType,
  Patient,
  PriorAuthData,
  PriorAuthQueryPayload,
  PriorAuthResult as PriorAuthResultInterface,
  RedirectOrigin,
  SMARTAppStatus,
  SelectOption,
  Tab,
  WidgetIncomingEventName,
  WidgetOutgoingEventName,
} from './types';
import { CurrentContext } from './types/current-context';
import { useNotifications } from './hooks/useNotifications';
import { searchSessionIdManager } from './utils/searchSessionIdManager';
import { SetQueryPayload } from './types/queryPayload';

const { Slide, ToastContainer, createToast, ToastTypes } = Toast;

const patientSessionMap = new Map<string, boolean>();

const App: React.FC = () => {
  const [priorAuthAnalyticsQuery, setPriorAuthAnalyticsQuery] = useState<PriorAuthAnalyticsQuery>();
  const [searchPriorAuthPayload, setSearchPriorAuthPayload] = useState<PriorAuthQueryPayload>();
  const [searchPriorAuthResult, setSearchPriorAuthResult] = useState<PriorAuthResultInterface>();
  const [cptFreeTextOptions, setCptFreeTextOptions] = useState<FreeTextResult[] | undefined>();
  const [cptQueryLoading, setCptQueryLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.PriorAuthInquiry);
  const [cptQueryError, setCptQueryError] = useState(false);
  const [icdFreeTextOptions, setIcdFreeTextOptions] = useState<FreeTextResult[] | undefined>();
  const [icdQueryLoading, setIcdQueryLoading] = useState(false);
  const [icdQueryError, setIcdQueryError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentContext, setCurrentContext] = useState<CurrentContext>({});
  const [launchedComponents, setLaunchedComponents] = useState<{ [key: string]: boolean }>({});
  const [isSearchInProgress, setIsSearchInProgress] = useState<boolean>(false);
  const [productConfig, setProductConfig] = useState<
    Partial<RuntimeContract.Outgoing.InitialDataPayload> | undefined
  >();
  const [isEligibilityFailed, setIsEligibilityFailed] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<Entities.Provider | undefined>();
  const [displayNewFeatureTooltip, setDisplayNewFeatureTooltip] = useState<boolean>(false);

  const [shouldShowNewFeatureTooltip] = useFeatureFlag({
    defaultValue: false,
    flagName: 'ButtonGroup_shouldShowNewFeatureTooltip',
  });

  const showNpiSelectionScreen = useMemo<boolean | undefined>(
    () =>
      productConfig?.adapterConfig?.vimConnectCapabilities?.products?.[
        Infra.Apps.Product.PriorAuthCheck
      ]?.showNpiSelectionScreen,
    [productConfig],
  );

  const { search: queryString } = useLocation();
  const { shouldUseTabs } = parse(queryString, { parseBooleans: true });
  const useTabs = Boolean(shouldUseTabs);

  const displaySelectProviderScreen = !!showNpiSelectionScreen;

  const updateCurrentContext = useCallback((updateState: Partial<CurrentContext>) => {
    setCurrentContext((context) => ({ ...context, ...updateState }));
  }, []);

  const changeTabWidth = useCallback((tabWidth: string) => {
    sendSyncEventToVimConnectHubApp(widgetId, WidgetOutgoingEventName.ModifyWidgetSize, {
      size: {
        height: WIDGET_DEFAULT_HEIGHT,
        width: tabWidth,
      },
    });
  }, []);

  const goToTab = useCallback((tab: Tab) => {
    setCurrentTab(tab);
  }, []);
  const submitPriorAuth = (skipFormValidation = false) => {
    if (!skipFormValidation) {
      if (!searchPriorAuthPayload) {
        return;
      }
      updateCurrentContext({
        searchedCpts: searchPriorAuthPayload.procedures.length
          ? searchPriorAuthPayload.procedures.map((p) => p.value)
          : undefined,
        searchedIcds: searchPriorAuthPayload.diagnosis
          ? [searchPriorAuthPayload.diagnosis.value]
          : undefined,
      });
    }
    updateLaunchedComponents({ [Tab.SubmitCase]: false });
    goToTab(Tab.SubmitCase);

    priorAuthLogger.info('Submit prior auth clicked - redirect to PACS', {
      searchPriorAuthPayload,
      context: currentContext,
    });
    const { patient, ...context } = currentContext;
    const logContext = {
      ...context,
    } as any;
    if (patient) {
      logContext.patient = {
        contentSupplierInternalPersonIds: patient.contentSupplierInternalPersonIds,
        contentSupplierIdentifiers: patient.contentSupplierIdentifiers,
      };
    }
    priorAuthLogger.info('Submit prior auth clicked - redirect to PACS', {
      searchPriorAuthPayload,
      context: {
        ...logContext,
      },
      noPHI: true,
    });
  };

  const updateLaunchedComponents = (tab: { [key: string]: boolean }) => {
    setLaunchedComponents((components) => ({ ...components, ...tab }));
  };

  const resetFreeTextOptions = useCallback((type: FreeTextType | FreeTextType[]) => {
    const types: FreeTextType[] = Array.isArray(type) ? type : [type];
    types.forEach((freeTextType) => {
      if (freeTextType === FreeTextType.Cpt) {
        setCptFreeTextOptions(undefined);
        setCptQueryError(false);
        setCptQueryLoading(false);
      }
      if (freeTextType === FreeTextType.Icd) {
        setIcdFreeTextOptions(undefined);
        setIcdQueryError(false);
        setIcdQueryLoading(false);
      }
    });
  }, []);

  const clearPriorAuthResult = useCallback(() => {
    setSearchPriorAuthResult(undefined);
    updateCurrentContext({
      selectedProvider: undefined,
    });
    setIsSearchInProgress(false);
  }, [updateCurrentContext]);

  const onFreeTextQuery = useCallback(
    (payload: FreeTextQueryPayload) => {
      const freeTextPreSendHandlers = {
        [FreeTextType.Icd]: () => {
          setIcdQueryLoading(true);
          setIcdFreeTextOptions(undefined);
        },
        [FreeTextType.Cpt]: () => {
          setCptQueryLoading(true);
          setCptFreeTextOptions(undefined);
        },
      };

      const { type, freeText } = payload;
      if (!freeText) {
        resetFreeTextOptions(type);
        return;
      }

      freeTextPreSendHandlers[type]();
      sendEventToAppVimConnectUIWidget(widgetId, WidgetOutgoingEventName.FreeTextQuery, payload);
    },
    [resetFreeTextOptions],
  );

  const onSearchClick = useCallback(
    (payload: PriorAuthQueryPayload) => {
      const analyticsQuery: PriorAuthAnalyticsQuery = {
        diagnosis_code: payload?.diagnosis?.value,
        procedure_codes: payload?.procedures?.map((procedure) => procedure?.value),
        type: 'manual',
      };
      setPriorAuthAnalyticsQuery(analyticsQuery);
      const eventPayload: PriorAuthQueryPayload = {
        ...payload,
        insurer: currentContext.patient?.insurance?.insurer,
      };
      sendEventToAppVimConnectUIWidget(
        widgetId,
        WidgetOutgoingEventName.SearchPriorAuth,
        eventPayload,
      );
    },
    [currentContext.patient],
  );

  const { showNotification, hideNotification } = useNotifications(submitPriorAuth, goToTab);

  const resetAppState = useCallback(
    (shouldUpdateCurrentContext: boolean = true) => {
      goToTab(Tab.PriorAuthInquiry);
      shouldUpdateCurrentContext &&
        updateCurrentContext({
          patient: undefined,
          encounterId: undefined,
          selectedProvider: undefined,
          searchedCpts: undefined,
          searchedIcds: undefined,
        });
      setLoading(false);
      resetFreeTextOptions([FreeTextType.Cpt, FreeTextType.Icd]);
      clearPriorAuthResult();
      setPriorAuthAnalyticsQuery(undefined);
      setLaunchedComponents({ [Tab.SubmitCase]: false, [Tab.StatusCheck]: false });
      setSelectedProvider(undefined);
      setSearchPriorAuthPayload(undefined);
      hideNotification();
    },
    [goToTab, updateCurrentContext, resetFreeTextOptions, clearPriorAuthResult, hideNotification],
  );

  useEffect(() => {
    const handlePatientEvent = (patient: Patient | undefined) => {
      if (!patient) return;

      const { insurance, contentSupplierIdentifiers } = patient;
      const insurer = insurance?.insurer;
      if (!insurer) {
        return;
      }

      const doesInsurerHaveMemberTokens =
        Object.keys(contentSupplierIdentifiers?.[insurer] ?? {}).length > 0;
      if (doesInsurerHaveMemberTokens) {
        updateCurrentContext({ patient });
      }
    };

    const handleEvents = async (payload: { event: WidgetIncomingEventName; data: any }) => {
      const { event, data } = payload;
      switch (event) {
        case WidgetIncomingEventName.PatientInContext: {
          const patientInContext = data as Patient;
          handlePatientEvent(patientInContext);
          break;
        }
        case WidgetIncomingEventName.PatientOutOfContext: {
          resetAppState();
          break;
        }
        case WidgetIncomingEventName.EncounterStart: {
          updateCurrentContext({ encounterId: data });
          break;
        }
        case WidgetIncomingEventName.OrderCreated:
        case WidgetIncomingEventName.OrderViewed: {
          const { patient: transformedPatient } = data as Standard.Events.OrderViewedPayload;
          handlePatientEvent(transformedPatient as Patient);

          setIsSearchInProgress(true);
          if (currentTab !== Tab.PriorAuthInquiry) {
            goToTab(Tab.PriorAuthInquiry);
          }
          break;
        }
        case WidgetIncomingEventName.OrderClosed: {
          resetAppState(false);
          break;
        }
        case WidgetIncomingEventName.FreeTextQueryResult: {
          const { type, result } = (data as FreeTextQueryResult) || {};
          if (type === FreeTextType.Cpt) {
            setCptFreeTextOptions(result);
            setCptQueryError(result === undefined);
            setCptQueryLoading(false);
          }
          if (type === FreeTextType.Icd) {
            setIcdFreeTextOptions(result);
            setIcdQueryError(result === undefined);
            setIcdQueryLoading(false);
          }
          break;
        }
        case WidgetIncomingEventName.SearchPriorAuthResult: {
          setLoading(false);

          const priorAuthData: PriorAuthData = data?.data ?? { procedures: [] };
          const error: Error = data?.error;
          error &&
            analyticsClient.track('prior_auth_error', {
              ...priorAuthAnalyticsQuery,
              error: error.message,
            });

          const procedures: SelectOption[] = priorAuthData.procedures.map((p) => {
            return { value: p.code, label: p.description };
          });
          const searchPayload: PriorAuthQueryPayload = { procedures };

          if (priorAuthData.diagnosis) {
            searchPayload.diagnosis = {
              value: priorAuthData.diagnosis.code,
              label: priorAuthData.diagnosis.description,
            };
          }

          setSearchPriorAuthPayload(searchPayload);
          const sessionPayload = {
            priorAuthQueryPayload: searchPayload.procedures,
            patientId: currentContext.patient?.vimPatientId,
          };
          searchSessionIdManager.handleShouldUpdate(sessionPayload);

          if (useTabs) {
            showNotification({ priorAuthData });
          }
          setSearchPriorAuthResult({ priorAuthData, error });
          setPriorAuthAnalyticsQuery(undefined);
          break;
        }
        case WidgetIncomingEventName.ReceivePriorAuthAnalytics: {
          setPriorAuthAnalyticsQuery(data);
          data &&
            analyticsClient.track('prior_auth_query', {
              ...data,
              content_supplier_patient_id: getContentSupplierId(currentContext),
            } as PriorAuthAnalyticsQuery);
          break;
        }
        case WidgetIncomingEventName.PopToast: {
          const { success, params } = data;
          const { launchId, srn } = params;
          if (!success) {
            priorAuthLogger.error('copying service reference number to clipboard failed', {
              payload,
              launchId,
              srn,
            });
            priorAuthLogger.error('copying service reference number to clipboard failed', {
              launchId,
              srn,
              noPHI: true,
            });
            analyticsClient.trackWithContext('copy_SRN_to_clipboard', {
              launchId,
              srn,
              status: SMARTAppStatus.Failure,
            });
            return;
          }

          priorAuthLogger.info('copying service reference number to clipboard succeed', {
            payload,
            launchId,
            srn,
          });
          priorAuthLogger.info('copying service reference number to clipboard succeed', {
            launchId,
            srn,
            noPHI: true,
          });
          analyticsClient.trackWithContext('copy_SRN_to_clipboard', {
            launchId,
            srn,
            status: SMARTAppStatus.Success,
          });
          createToast({
            title: 'Success!',
            message: 'Service Reference Number was copied to clipboard',
            type: ToastTypes.SUCCESS,
            html: true,
          });
          break;
        }
        case WidgetIncomingEventName.GetProductConfig: {
          priorAuthLogger.info('Widget got product config', {
            data,
          });
          setProductConfig(data);
          const {
            adapterName,
            workspaceConfig,
            adapterConfig,
            applicationConfig,
            organizationConfig,
            products,
            isLoggedIn,
            publishedEventsState,
            userToTokensMap,
            organizationId,
            organizationName,
            organizationEhrSystem,
            isAccountActive,
            vimConnectUser,
            currentUrl,
            pubsubId,
            runtimeId,
          } = data;
          priorAuthLogger.info('Widget got product config', {
            data: {
              adapterName,
              workspaceConfig,
              adapterConfig,
              applicationConfig,
              organizationConfig,
              products,
              isLoggedIn,
              userToTokensMap,
              organizationId,
              organizationName,
              organizationEhrSystem,
              isAccountActive,
              vimConnectUser,
              currentUrl,
              pubsubId,
              runtimeId,
              publishedEventsState: publishedEventsState ? Object.keys(publishedEventsState) : [],
            },
            noPHI: true,
          });
          break;
        }
      }
    };

    registerVimConnectUIWidget(widgetId, handleEvents);
  }, [
    resetAppState,
    priorAuthAnalyticsQuery,
    updateCurrentContext,
    currentContext,
    currentTab,
    goToTab,
    showNotification,
    useTabs,
  ]);

  useEffect(
    () => analyticsClient.setContext(currentContext, currentTab),
    [currentContext, currentTab],
  );

  useEffect(() => {
    const vimPatientId = currentContext?.patient?.vimPatientId;
    if (!vimPatientId) {
      return setDisplayNewFeatureTooltip(false);
    }

    const isMapped = patientSessionMap.get(vimPatientId);
    if (!isMapped) {
      patientSessionMap.set(vimPatientId, true);
    }

    setDisplayNewFeatureTooltip(!isMapped);
  }, [currentContext?.patient?.vimPatientId]);

  const updateEligibility = useCallback(
    async (context: CurrentContext): Promise<CurrentContext | undefined> => {
      let timeoutPromise: Promise<CurrentContext> | undefined;

      const updateEligibilityPayload = {
        patient: context.patient,
        encounterId: context.encounterId ?? context.patient?.patientId,
        npi: context?.selectedProvider?.npi,
        provider: {
          npi: context?.selectedProvider?.npi,
          firstName: context?.selectedProvider?.demographics?.firstName,
          lastName: context?.selectedProvider?.demographics?.lastName,
        },
      };

      const { patient, encounterId, npi, provider } = updateEligibilityPayload;
      const logContext = {
        encounterId,
        npi,
        provider,
      } as any;
      if (patient) {
        logContext.patient = {
          contentSupplierInternalPersonIds: patient.contentSupplierInternalPersonIds,
          contentSupplierIdentifiers: patient.contentSupplierIdentifiers,
        };
      }

      eligibilityLogger.info('Update eligibility executed', updateEligibilityPayload);
      eligibilityLogger.info('Update eligibility executed', {
        updateEligibilityPayload: {
          ...logContext,
        },
        noPHI: true,
      });

      let isMemberTokenValid = false;

      try {
        const transactionId = await sendSyncEventToVimConnectHubApp(
          widgetId,
          WidgetOutgoingEventName.UpdateEligibility,
          updateEligibilityPayload,
        );

        isMemberTokenValid = !!transactionId;
        updateCurrentContext({ transactionId });
        timeoutPromise = new Promise<CurrentContext>((resolve) => {
          setTimeout(() => {
            eligibilityLogger.info('Waited 5 seconds for eligibility update', { noPHI: true });
            resolve({ ...context, transactionId });
          }, 5000);
        });
      } catch (error: any) {
        eligibilityLogger.error('Update eligibility failed', {
          updateEligibilityPayload,
          error,
        });
        eligibilityLogger.error('Update eligibility failed', {
          updateEligibilityPayload: {
            ...logContext,
          },
          error: error?.message,
          noPHI: true,
        });
      }

      if (!isMemberTokenValid) {
        eligibilityLogger.warning('Update eligibility failed', updateEligibilityPayload);
        eligibilityLogger.warning('Update eligibility failed', {
          updateEligibilityPayload: {
            ...logContext,
          },
          noPHI: true,
        });
      }
      setIsEligibilityFailed(!isMemberTokenValid);

      return await timeoutPromise;
    },
    [updateCurrentContext],
  );

  const confirmSelectedProvider = useCallback(
    (provider: Entities.Provider) => {
      const context = { ...currentContext, selectedProvider: provider };
      updateCurrentContext(context);
      return context;
    },
    [currentContext, updateCurrentContext],
  );

  return (
    <ThemeVariablesWrapper theme={themes.vim}>
      <div>
        <ApplicationTitle title="Prior Auth" />
        <div className="app-content">
          {/* eslint-disable no-nested-ternary */}
          {loading ? (
            <Loader color="grey" size="small" type="dots" />
          ) : (
            <div>
              {useTabs &&
                (shouldShowNewFeatureTooltip ? (
                  <NewFeatureTooltip
                    className="block"
                    placement={PlacementEnum.TOP}
                    showCircleAnimation={false}
                    tooltipContent={
                      <>
                        <strong>New!</strong> Click to submit Prior Auth
                      </>
                    }
                    visible={displayNewFeatureTooltip}
                  >
                    <ButtonGroupComponent
                      items={optionalTabs.map((tab) => ({
                        value: tab,
                        text: tab,
                      }))}
                      bgColor="none"
                      buttonType="tab"
                      className="tabs-buttons-group"
                      direction="row"
                      value={currentTab}
                      onChange={(selectedTab: Tab) => {
                        priorAuthLogger.info('prior auth tab clicked', {
                          tab: selectedTab,
                          noPHI: true,
                        });
                        setCurrentTab(selectedTab);
                        analyticsClient.track('prior_auth_tab_switch', {
                          tabName: selectedTab,
                          redirected_from: RedirectOrigin.APP_TAB,
                        });
                      }}
                      style={{ fontWeight: 'bold', marginBottom: '20px' }}
                    />
                  </NewFeatureTooltip>
                ) : (
                  <ButtonGroupComponent
                    items={optionalTabs.map((tab) => ({
                      value: tab,
                      text: tab,
                      displayNewFeatureTooltip: tab === Tab.SubmitCase,
                    }))}
                    bgColor="none"
                    buttonType="tab"
                    direction="row"
                    value={currentTab}
                    onChange={(selectedTab: Tab) => {
                      priorAuthLogger.info('prior auth tab clicked', {
                        tab: selectedTab,
                        noPHI: true,
                      });
                      setCurrentTab(selectedTab);
                      analyticsClient.track('prior_auth_tab_switch', {
                        tabName: selectedTab,
                        redirected_from: RedirectOrigin.APP_TAB,
                      });
                    }}
                    style={{ fontWeight: 'bold', marginBottom: '20px' }}
                  />
                ))}
              <Content
                currentTab={currentTab}
                onFreeTextQuery={onFreeTextQuery}
                onSearchClick={onSearchClick}
                cptFreeTextOptions={cptFreeTextOptions}
                cptQueryLoading={cptQueryLoading}
                cptQueryError={cptQueryError}
                icdFreeTextOptions={icdFreeTextOptions}
                icdQueryLoading={icdQueryLoading}
                icdQueryError={icdQueryError}
                resetFreeTextOptions={resetFreeTextOptions}
                priorAuthResult={searchPriorAuthResult}
                clearPriorAuthResult={clearPriorAuthResult}
                queryPayload={searchPriorAuthPayload}
                setQueryPayload={setSearchPriorAuthPayload as SetQueryPayload}
                useTabs={useTabs}
                goToTab={goToTab}
                changeTabWidth={changeTabWidth}
                currentContext={currentContext}
                updateLaunchedComponents={updateLaunchedComponents}
                launchedComponents={launchedComponents}
                submitPriorAuth={submitPriorAuth}
                updateEligibility={updateEligibility}
                isSearchInProgress={isSearchInProgress}
                setIsSearchInProgress={setIsSearchInProgress}
                displaySelectProviderScreen={displaySelectProviderScreen}
                confirmSelectedProvider={confirmSelectedProvider}
                setSelectedProvider={setSelectedProvider}
                selectedProvider={selectedProvider}
                isEligibilityFailed={isEligibilityFailed}
              />
            </div>
          )}
        </div>
        <ToastContainer
          className=""
          position="bottom-right"
          hideProgressBar
          autoClose={3000}
          transition={Slide}
        />
      </div>
    </ThemeVariablesWrapper>
  );
};

export default App;
