import { sendSyncEventToVimConnectHubApp } from '@getvim/utils-vim-connect-communication';
import { widgetId } from '../../../consts';
import { priorAuthLogger } from '../../../logger';
import { LaunchMetadata, SMARTApp, WidgetOutgoingEventName } from '../../../types';
import { CurrentContext } from '../../../types/current-context';

interface SmartAppLaunchUrlParams {
  smartApp: SMARTApp;
  context: CurrentContext;
}

export const getSmartAppLaunchUrl = async ({
  smartApp,
  context,
}: SmartAppLaunchUrlParams): Promise<string | undefined> => {
  const { patient, selectedProvider, encounterId, searchedIcds, searchedCpts, transactionId } =
    context;
  const { patientId, contentSupplierInternalPersonIds, contentSupplierIdentifiers } = patient || {};
  if (!selectedProvider || !patientId) {
    priorAuthLogger.error(`UHC ${smartApp} - missing selectedProvider/patientId`, {
      selectedProvider,
      patientId,
    });
    priorAuthLogger.error(`UHC ${smartApp} - missing selectedProvider/patientId`, {
      selectedProvider,
      patient: { contentSupplierInternalPersonIds, contentSupplierIdentifiers },
      noPHI: true,
    });
    return undefined;
  }

  const launchEncounterId = encounterId ?? patientId;

  const launchMetadata: LaunchMetadata = await sendSyncEventToVimConnectHubApp(
    widgetId,
    WidgetOutgoingEventName.LaunchSmartApp,
    {
      smartApp,
      initialLaunchContext: {
        patientId,
        encounterId: launchEncounterId,
        selectedNpi: selectedProvider?.npi,
        transactionId,
        ...(smartApp === SMARTApp.PACS && searchedIcds?.length && { icds: searchedIcds }),
        ...(smartApp === SMARTApp.PACS && searchedCpts?.length && { cpts: searchedCpts }),
      },
    },
  );

  return launchMetadata.smartAppUrl;
};
