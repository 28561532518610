import React, { FC } from 'react';
import {
  ColorNameEnum,
  InputStyle,
  InputStyleWrapper,
  SizeEnum,
  Text,
  WeightEnum,
  Select,
} from '@getvim/atomic-ui';
import { formatOptions } from './formatOptions';
import {
  FreeTextQueryPayload,
  FreeTextResult,
  FreeTextType,
  PriorAuthQueryPayload,
  SelectOption,
} from '../../../types';
import { queryAutocompleteOverload } from './queryAutocompleteOptions';
import { ERROR, NO_RESULTS, START_SEARCH } from '../../../consts/selectInputMessages';
import './searchDiagnosis.less';
import { SetQueryPayloadFunction } from '../../../types/queryPayload';

interface SearchDiagnosisProps {
  diagnosis: SelectOption | undefined;
  setQueryPayload: SetQueryPayloadFunction;
  onFreeTextQuery: (payload: FreeTextQueryPayload) => void;
  icdFreeTextOptions: FreeTextResult[] | undefined;
  resetFreeTextOptions: (type: FreeTextType) => void;
  disabled: boolean;
  loading: boolean;
  error: boolean;
}

const SearchDiagnosis: FC<SearchDiagnosisProps> = ({
  diagnosis,
  setQueryPayload,
  onFreeTextQuery,
  icdFreeTextOptions,
  resetFreeTextOptions,
  disabled,
  loading,
  error,
}: SearchDiagnosisProps) => {
  const queryAutocompleteOptions = queryAutocompleteOverload(onFreeTextQuery);

  const onDiagnosisChange = (items: SelectOption[]) => {
    const diagnosis = items ? items[0] : undefined;

    setQueryPayload((prevState: PriorAuthQueryPayload) => {
      return { ...prevState, diagnosis };
    });
    resetFreeTextOptions(FreeTextType.Icd);
  };

  const onSearchInputChanged = ({ state }) => {
    queryAutocompleteOptions(state.search, 'input-change', FreeTextType.Icd);
  };

  const noDataLabel = () => {
    if (error) {
      return ERROR;
    }
    return icdFreeTextOptions === undefined ? START_SEARCH : NO_RESULTS;
  };

  return (
    <div className="search-diagnosis">
      <div className="margin-top-20">
        <Text
          text="Enter diagnosis code"
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.medium}
          size={SizeEnum['14px']}
          inline
        />
        &nbsp;
        <Text text="(optional)" weight={WeightEnum.medium} size={SizeEnum['14px']} inline />
      </div>

      <div className="margin-top-5 margin-bottom-20">
        <InputStyleWrapper
          leftIcon={<i className="icon-search" />}
          inputStyle={InputStyle.medium}
          className="search-diagnosis-input-wrapper input-wrapper "
        >
          <div className="input">
            <Select
              id="icd-select"
              name="diagnosis"
              options={formatOptions(icdFreeTextOptions ?? [])}
              values={
                diagnosis
                  ? [{ ...diagnosis, displayLabel: `${diagnosis.value} - ${diagnosis.label}` }]
                  : []
              }
              onChange={(items) => {
                onDiagnosisChange(items);
              }}
              searchFn={onSearchInputChanged}
              noDataLabel={noDataLabel()}
              isDisabled={disabled}
              loading={loading}
              clearable={!!diagnosis}
              error={error ? ERROR : undefined}
              noResultsLabel={NO_RESULTS}
              noInputLabel={START_SEARCH}
              labelField="displayLabel"
            />
          </div>
        </InputStyleWrapper>
      </div>
    </div>
  );
};

export default SearchDiagnosis;
