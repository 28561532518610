import React from 'react';
import { Button } from '@getvim/atomic-ui';
import './actionButton.less';

export interface ActionButtonProps {
  text: string;
  onClick: () => void;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ text, onClick }: ActionButtonProps) => (
  <div className="action-button-container">
    <Button text={text} buttonType="tiny" onClick={onClick} />
  </div>
);
