import React, { FC } from 'react';
import { ColorNameEnum, SizeEnum, WeightEnum, Text, AlignEnum } from '@getvim/atomic-ui';
import classNames from 'classnames';
import { SiteOfService, DecisionCode } from '../../../types';

interface SiteOfServiceProps {
  site: SiteOfService;
}

const mapDecisionCodeToClass = (code: number) => {
  if (code === DecisionCode.Required || code === DecisionCode.Blocked) return 'required';
  if (code === DecisionCode.NotRequired) return 'notRequired';
  return 'conditionally';
};

const SiteOfServiceItem: FC<SiteOfServiceProps> = ({ site }: SiteOfServiceProps) => {
  return (
    <div className={classNames('response-item', mapDecisionCodeToClass(site.code))}>
      <div className="response-item-icon">
        <i className="icon-" />
      </div>
      <div className="response-item-content">
        <Text
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.normal}
          size={SizeEnum['12px']}
          align={AlignEnum.left}
          inline
        >
          <span className={WeightEnum.bold}>{site.name}</span> - {site.decision}
        </Text>

        <ul className="response-item-list">
          {site.conditionsList?.map((condition) => (
            <li key={condition}>{condition}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SiteOfServiceItem;
