import { AnalyticsClient } from '@getvim/utils-vim-connect-communication';
import { widgetId } from '../consts';
import { SMARTAppStatus, Tab } from '../types';
import { CurrentContext } from '../types/current-context';
import { AnalyticsContext, AnalyticsCopySrnToClipboard, AnalyticsTabSwitch } from './types';

export interface PriorAuthQuery {
  diagnosis_code?: string;
  procedure_codes: string[];
  vim_patient_id?: string;
  type: 'manual' | 'automatic';
  content_supplier_patient_id?: string;
}

export type PriorAuthAnalyticsEvents = {
  prior_auth_query: PriorAuthQuery;
  prior_auth_error: (PriorAuthQuery & { error: string }) | { error: string };
  smart_app_start_launching: Omit<AnalyticsContext, 'launchId'> & {
    searchedIcds: CurrentContext['searchedIcds'];
    searchedCpts: CurrentContext['searchedCpts'];
  };
  finish_launch_smartApp: AnalyticsContext & {
    status: SMARTAppStatus;
    searchedIcds: CurrentContext['searchedIcds'];
    searchedCpts: CurrentContext['searchedCpts'];
    vim_patient_id: string;
    content_supplier_patient_id: string;
  };
  got_close_request_from_smartApp: AnalyticsContext & {
    reason: string;
    status: SMARTAppStatus;
  };
  display_error_screen: AnalyticsContext;
  reset_button_clicked: AnalyticsContext;
  cancel_button_in_Vim_error_screen_clicked: AnalyticsContext;
  retry_button_in_Vim_error_screen_clicked: AnalyticsContext;
  copy_SRN_to_clipboard: AnalyticsContext & AnalyticsCopySrnToClipboard;
  prior_auth_tab_switch: AnalyticsTabSwitch;
};

export const getContextForAnalytics = (
  currentContext: CurrentContext,
  tabName: Tab,
): AnalyticsContext => {
  const { selectedProvider, encounterId } = currentContext;
  return {
    tabName,
    initialContext: {
      selectedProvider: {
        npi: selectedProvider?.npi,
        firstName: selectedProvider?.demographics?.firstName,
        lastName: selectedProvider?.demographics?.lastName,
      },
      encounterId,
    },
  };
};
class PriorAuthAnalyticsClient extends AnalyticsClient<PriorAuthAnalyticsEvents> {
  private context: AnalyticsContext = { tabName: Tab.PriorAuthInquiry };

  public setContext(context: CurrentContext, tabName: Tab) {
    this.context = getContextForAnalytics(context, tabName);
  }

  public trackWithContext(event, properties) {
    this.track(event, { ...properties, ...this.context });
  }
}

export const analyticsClient = new PriorAuthAnalyticsClient(widgetId);

export const getContentSupplierId = (context: CurrentContext) => {
  const insurer = context.patient?.insurance?.insurer;
  return insurer && context.patient?.contentSupplierIdentifiers
    ? context.patient.contentSupplierIdentifiers[insurer]?.contentSupplierId
    : undefined;
};
