import { logger } from '@getvim/vim-connect-logger';

export const priorAuthLogger = logger.scope('Prior Auth Client', {
  moduleAndScopeSameColor: true,
  moduleName: 'Prior Auth',
});

export const eligibilityLogger = logger.scope('Prior Auth Client - Eligibility', {
  moduleAndScopeSameColor: true,
  moduleName: 'Prior Auth',
});
