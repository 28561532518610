import React, { FC, useCallback } from 'react';
import { ColorNameEnum, SizeEnum, WeightEnum, Button, Text } from '@getvim/atomic-ui';

export interface PriorAuthResultErrorProps {
  error: Error | undefined;
  onBackButtonClick: () => void;
}

const PriorAuthResultError: FC<PriorAuthResultErrorProps> = ({
  error,
  onBackButtonClick,
}: PriorAuthResultErrorProps) => {
  const getErrorString = useCallback(
    () =>
      error?.message?.includes('InvalidIcdOrCpt')
        ? 'Procedure or diagnosis code not found.'
        : 'Something went wrong.',
    [error],
  );

  return (
    <div className="prior-auth-result-error">
      <Text
        className="margin-bottom-10"
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.bold}
        size={SizeEnum['18px']}
      >
        {getErrorString()}
      </Text>
      <Text
        className="margin-bottom-30"
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.normal}
        size={SizeEnum['14px']}
      >
        Please try a new search.
      </Text>
      <Button width="block" buttonType="small" onClick={onBackButtonClick}>
        Back to search
      </Button>
    </div>
  );
};

export default PriorAuthResultError;
