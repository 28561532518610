import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './index.less';

export enum PlacementEnum {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

type NewFeatureTooltipProps = {
  className?: string;
  tooltipContent: any;
  placement?: PlacementEnum;
  showCircleAnimation?: boolean;
  visible?: boolean;
};

function NewFeatureTooltip({
  children,
  className,
  tooltipContent,
  placement = PlacementEnum.TOP,
  showCircleAnimation = true,
  visible = true,
}: PropsWithChildren<NewFeatureTooltipProps>) {
  return (
    <>
      {visible ? (
        <div className={classNames('new-feature-tooltip-wrap', className)}>
          {showCircleAnimation && <div className="circle-animation" />}
          {children}
          <div className={classNames('new-feature-tooltip', `${placement}`)}>
            <div className="new-feature-tooltip-content">{tooltipContent}</div>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default NewFeatureTooltip;
