import { useCallback, useState } from 'react';
import { ActionButton, ActionButtonAction, BtnType } from '@getvim/utils-vim-connect-communication';
import { Notifications } from '@getvim/vim-connect-communication';
import { decisionCodeMapping } from '../consts/decisionCodeMapping';
import { DecisionCode, PriorAuthData, Tab } from '../types';
import { useCtaEvent } from './useCTAEvent';
import { searchSessionIdManager } from '../utils/searchSessionIdManager';

export const widgetId = 'prior-auth';

const decisionSummaryToNotification = {
  'Notification/Prior Authorization not required if procedure performed in Ambulatory Surgical Center or Office; otherwise may be required for this service.':
    'Prior Authorization may be required. However, it is not required if the procedure is performed in ASC or Office.',
  'This code requires a Genetic / Molecular Testing Prior Authorization. To submit an online request for prior authorization for the requested services, you may go to www.uhcprovider.com and click on the UnitedHealthcare Provider Portal icon at the top of the page to log into the Prior Authorization and Notification application. Once logged in please select the CREATE NEW SUBMISSIONS link in the Prior Authorization and Notification tile.':
    'Notification / Prior Authorization may be required for this service as it requires a Genetic / Molecular Testing Prior Authorization.',
};

const notify = (id: string, notificationMessage: string, actionButtons?: ActionButton[]) => {
  Notifications.Actions.showNotification({
    widgetId: 'prior-auth',
    text: notificationMessage,
    notificationId: id,
    options: { timeoutInMs: 10_000 },
    actionButtons,
  });
};

const generateMessage = (priorAuthData: PriorAuthData): string | undefined => {
  const specialMessage =
    decisionSummaryToNotification[priorAuthData.procedures[0]?.decisionSummary];
  if (specialMessage) {
    return specialMessage;
  }
  if (!priorAuthData.procedures[0]?.sitesOfService?.[0].code) return;
  if (priorAuthData.procedures[0]?.sitesOfService[0]?.code === DecisionCode.Blocked) {
    const customMessage = priorAuthData.procedures[0]?.decisionSummary;
    return highlightText(customMessage);
  }
  const message = decisionCodeMapping[priorAuthData.procedures[0]?.sitesOfService[0].code];
  return highlightText(message);
};

const highlightText = (text: string) => {
  return text
    .replace(/is required/i, '<b>is required</b>')
    .replace(/not required/i, '<b>not required</b>')
    .replace(/may be required/i, '<b>may be required</b>')
    .replace(/requires/i, '<b>requires</b>')
    .replace(/is not required/i, '<b>is not required</b>');
};

const underlineStyle = {
  'text-decoration': 'underline',
  'font-size': '14px',
};

const seeMoreDetailsButton = {
  text: 'See more details',
  action: ActionButtonAction.SEE_MORE_DETAILS,
  buttonType: BtnType.LINK,
  className: 'text-ultra-dark',
  style: underlineStyle,
};

const supportedCtaButtons = [
  seeMoreDetailsButton,
  {
    text: 'Submit case',
    action: ActionButtonAction.SUBMIT_CASE,
    buttonType: BtnType.TINY,
  },
];

const blockedCtaButtons = [
  seeMoreDetailsButton,
  {
    text: 'Open portal',
    action: ActionButtonAction.EXTERNAL_LINK,
    buttonType: BtnType.TINY,
  },
];

const notificationCtas = {
  supported: supportedCtaButtons,
  blocked: blockedCtaButtons,
};

const isBlocked = (priorAuthData: PriorAuthData) =>
  !!priorAuthData.procedures?.[0]?.sitesOfService.find(({ code }) => code === DecisionCode.Blocked);

export const useNotifications = (
  submitPriorAuth: () => void,
  goToTab: (tab: Tab) => void,
): {
  showNotification: ({ priorAuthData }: { priorAuthData: PriorAuthData }) => void;
  hideNotification: () => void;
} => {
  const [lastNotifiedSessionId, setLastNotifiedSessionId] = useState<string>();

  useCtaEvent(widgetId, goToTab, submitPriorAuth);

  const hideNotification = useCallback(() => {
    if (lastNotifiedSessionId) {
      Notifications.Actions.hideNotification({
        widgetId,
        notificationId: lastNotifiedSessionId,
      });
      setLastNotifiedSessionId(undefined);
    }
  }, [lastNotifiedSessionId]);

  const showNotification = useCallback(({ priorAuthData }: { priorAuthData: PriorAuthData }) => {
    const notificationMessage = generateMessage(priorAuthData);
    const sessionId = searchSessionIdManager.getSessionId();
    if (!notificationMessage || !sessionId) return;
    const ctaButtons = notificationCtas[isBlocked(priorAuthData) ? 'blocked' : 'supported'];
    notify(sessionId, notificationMessage, ctaButtons);
    setLastNotifiedSessionId(sessionId);
  }, []);

  return { showNotification, hideNotification };
};
