export enum FreeTextType {
  Cpt = 'cpt',
  Icd = 'icd',
}

export interface FreeTextQueryPayload {
  type: FreeTextType;
  freeText: string;
}

export interface FreeTextResult {
  taxonomyCode: string;
  taxonomyDisplayName: string;
}

export interface FreeTextQueryResult {
  type: FreeTextType;
  result: FreeTextResult[];
}
