import { priorAuthLogger } from '../../../../logger';
import { Tab } from '../../../../types';
import { getParamFromUrlQueryString } from '../../../../url-helper';

interface WidgetListenerOptions {
  smartAppUrl: string;
  tab: Tab;
}

interface WidgetListenerProps {
  onSuccess: (data: any, successLaunchId?: string) => void;
  onFail: (data: any, failureLaunchId?: string) => void;
  options: WidgetListenerOptions;
}

// values determined by POCA, apps sending post message with outcome with those values
const FAILED_OUTCOME = new Set(['4', '8']);
const SUCCEED_OUTCOME = new Set(['1', '2']);

const listenerMap = new Map();

export const widgetListener = ({ onSuccess, onFail, options }: WidgetListenerProps): void => {
  const { smartAppUrl, tab } = options;

  const handler = async (message: any) => {
    const { origin, data } = message || {};

    const urlPattern = 'https://[^ ]*.optum.com[^ ]*';
    const isOptumOrigin = new RegExp(urlPattern).test(origin);

    if (isOptumOrigin && typeof data === 'string') {
      const parsedData = getPostMessageParsedData(data);
      const { Outcome, LaunchId } = parsedData;

      const launchId = getParamFromUrlQueryString(smartAppUrl, 'launch');
      if (LaunchId && launchId !== LaunchId) return;
      if (!LaunchId) {
        priorAuthLogger.error('failed to get launchId from postMessage data', {
          postMessageData: parsedData,
          noPHI: true,
        });
      }

      if (FAILED_OUTCOME.has(Outcome)) {
        onFail(parsedData, launchId);
      } else if (SUCCEED_OUTCOME.has(Outcome)) {
        onSuccess(parsedData, launchId);
      } else {
        priorAuthLogger.error('failed to get outcome from postMessage data', {
          postMessageData: parsedData,
          noPHI: true,
        });
      }
    }
  };
  window.addEventListener('message', handler);

  listenerMap.set(tab, handler);
};

export const removeListener = (tab: Tab): void => {
  if (listenerMap.has(tab)) {
    window.removeEventListener('message', listenerMap.get(tab));
    listenerMap.delete(tab);
  }
};

const getPostMessageParsedData = (data: string) => {
  try {
    const parsed = JSON.parse(data);
    if (!parsed) {
      priorAuthLogger.error('Parsing PACS/PACSU postMessage data is null', { data });
      priorAuthLogger.error('Parsing PACS/PACSU postMessage data is null', { noPHI: true });
      return {};
    }
    return parsed;
  } catch (error: any) {
    priorAuthLogger.error('Failed to parse PACS/PACSU postMessage data', { data });
    priorAuthLogger.error('Failed to parse PACS/PACSU postMessage data', {
      error: error?.message,
      noPHI: true,
    });
  }
};
