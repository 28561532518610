import { Loader } from '@getvim/atomic-ui';
import { Entities } from '@getvim/vim-connect';
import React, { useCallback, useEffect, useState } from 'react';
import { analyticsClient, getContentSupplierId } from '../../../analytics/analytics';
import { iframeWidth, WIDGET_DEFAULT_WIDTH } from '../../../consts/widgetSize';
import { priorAuthLogger } from '../../../logger';
import { SMARTAppStatus, Tab, tabToSmartApp } from '../../../types';
import { CurrentContext } from '../../../types/current-context';
import { getParamFromUrlQueryString } from '../../../url-helper';
import { ErrorScreen } from '../../errors/ErrorScreen';
import { ActionButton } from '../../action-button/ActionButton';
import SelectProvider from '../../select-provider/selectProvider';
import { widgetListener } from './listeners/listener';
import { getSmartAppLaunchUrl } from './smartAppLauncher';
import './uhcSmartAppWrapper.less';

export interface UhcSmartAppWrapperProps {
  tab: Tab;
  currentContext: CurrentContext;
  goToTab: (tab: Tab) => void;
  currentTab: Tab;
  updateLaunchedComponents: (tab: { [key: string]: boolean }) => void;
  shouldRelaunch?: boolean;
  isEligibilityFailed: boolean;
  displaySelectProviderScreen: boolean;
  confirmSelectedProvider: (selectedProvider: Entities.Provider) => CurrentContext;
  setSelectedProvider: (SelectOption: Entities.Provider) => void;
  selectedProvider: Entities.Provider | undefined;
  updateEligibility: (context: CurrentContext) => Promise<CurrentContext | undefined>;
  changeTabWidth: (tabWidth: string) => void;
}

const getLaunchId = (url: string): string | undefined => {
  if (!url) return;
  return getParamFromUrlQueryString(url, 'launch');
};

const UhcSmartAppWrapper: React.FC<UhcSmartAppWrapperProps> = ({
  tab,
  currentContext,
  goToTab,
  currentTab,
  updateLaunchedComponents,
  shouldRelaunch,
  isEligibilityFailed,
  displaySelectProviderScreen,
  confirmSelectedProvider,
  setSelectedProvider,
  updateEligibility,
  selectedProvider,
  changeTabWidth,
}: UhcSmartAppWrapperProps) => {
  const [iframeUrl, setIframeUrl] = useState<string | undefined>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [flowSuccess, setFlowSuccess] = useState<boolean>(false);
  const [launchId, setLaunchId] = useState<string | undefined>();
  const [shouldSelectProvider, setShouldSelectProvider] = useState(displaySelectProviderScreen);

  const resetState = useCallback(() => {
    setFlowSuccess(false);
    setLoading(false);
    setHasError(false);
    setIframeUrl(undefined);
    setShouldSelectProvider(displaySelectProviderScreen);
    updateLaunchedComponents({ [tab]: false });
  }, [displaySelectProviderScreen, tab, updateLaunchedComponents]);

  useEffect(() => {
    if (currentTab !== tab) return;
    if (!shouldSelectProvider) {
      changeTabWidth(iframeWidth);
    } else {
      changeTabWidth(WIDGET_DEFAULT_WIDTH);
    }
  }, [changeTabWidth, currentTab, shouldSelectProvider, tab]);

  const startWidgetListener = useCallback(
    (smartAppUrl: string) => {
      widgetListener({
        onSuccess: (data: any, successLaunchId?: string) => {
          priorAuthLogger.info(`UHC smart app [${tab}] finished successfully`, {
            launchId: successLaunchId,
            context: currentContext,
            finishReason: data.OutcomeDesc,
            postMessageData: data,
          });
          setFlowSuccess(true);
        },
        onFail: (data: any, failureLaunchId?: string) => {
          priorAuthLogger.error(`POCA postMessage response - UHC smart app [${tab}] failure`, {
            launchId: failureLaunchId,
            context: currentContext,
            postMessageData: data,
          });
          analyticsClient.trackWithContext('got_close_request_from_smartApp', {
            launchId: failureLaunchId,
            reason: data.OutcomeDesc,
            status: SMARTAppStatus.Failure,
          });
          setLoading(false);
          setHasError(true);
        },
        options: { smartAppUrl, tab },
      });
    },
    [tab, currentContext],
  );

  const callUpdateEligibility = useCallback(
    async (context: CurrentContext): Promise<CurrentContext | undefined> => {
      setLoading(true);
      const updatedContext = await updateEligibility(context);
      setLoading(false);
      return updatedContext;
    },
    [updateEligibility],
  );

  const launch = useCallback(
    async (context: CurrentContext) => {
      setLoading(true);
      setHasError(false);
      try {
        priorAuthLogger.info(`UHC smart app [${tab}] start launching`, { context });
        analyticsClient.trackWithContext('smart_app_start_launching', {
          searchedIcds: context.searchedIcds,
          searchedCpts: context.searchedCpts,
        });

        const launchUrl = await getSmartAppLaunchUrl({
          smartApp: tabToSmartApp[tab],
          context,
        });

        if (!launchUrl) {
          throw new Error(`POCA postMessage response - UHC smart app [${tab}] missing launch url`);
        }

        const launchIdParam: string | undefined = getLaunchId(launchUrl);
        priorAuthLogger.info(`UHC smart app [${tab}] launch data`, {
          context,
          launchId: launchIdParam,
          launchUrl,
        });
        setLaunchId(launchIdParam);
        startWidgetListener(launchUrl);
        setIframeUrl(launchUrl);

        analyticsClient.trackWithContext('finish_launch_smartApp', {
          launchId: launchIdParam,
          status: SMARTAppStatus.Success,
          searchedIcds: context.searchedIcds,
          searchedCpts: context.searchedCpts,
          vim_patient_id: context.patient?.vimPatientId,
          content_supplier_patient_id: getContentSupplierId(context),
        });
      } catch (error) {
        priorAuthLogger.error(`POCA postMessage response - UHC smart app [${tab}] launch failed`, {
          context,
          error,
        });
        analyticsClient.trackWithContext('finish_launch_smartApp', {
          status: SMARTAppStatus.Failure,
          searchedIcds: context.searchedIcds,
          searchedCpts: context.searchedCpts,
          vim_patient_id: context.patient?.vimPatientId,
        });
        setHasError(true);
      } finally {
        updateLaunchedComponents({ [tab]: true });
        setLoading(false);
      }
    },
    [startWidgetListener, tab, updateLaunchedComponents],
  );

  const displayError = hasError && !loading;
  useEffect(() => {
    if (displayError) {
      analyticsClient.trackWithContext('display_error_screen', {
        launchId,
      });
    }
  }, [displayError, currentContext, tab, launchId]);

  useEffect(() => {
    if (hasError && !loading) {
      priorAuthLogger.info(`UHC smart app [${tab}] - Error screen display`, {
        launchId,
        context: currentContext,
      });
    }
  }, [hasError, loading, currentContext, launchId, tab]);

  useEffect(() => {
    // Hide button's loader if selected provider's eligibility failed
    if (isEligibilityFailed && loading) {
      setLoading(false);
    }
  }, [isEligibilityFailed, loading]);

  const resubmit = () => {
    priorAuthLogger.info(`UHC smart app [${tab}] - Retry button in Vim's error screen clicked`, {
      launchId,
      context: currentContext,
    });
    analyticsClient.trackWithContext('retry_button_in_Vim_error_screen_clicked', {
      launchId,
    });
    launch(currentContext);
  };

  const cancel = () => {
    priorAuthLogger.info(`UHC smart app [${tab}] - Cancel button in Vim's error screen clicked`, {
      launchId,
      context: currentContext,
    });
    analyticsClient.trackWithContext('cancel_button_in_Vim_error_screen_clicked', {
      launchId,
    });
    resetState();
    if (!displaySelectProviderScreen) {
      goToTab(Tab.PriorAuthInquiry);
    }
  };

  const reset = () => {
    resetState();
    priorAuthLogger.info(`UHC smart app [${tab}] - Reset button clicked`, {
      launchId,
      context: currentContext,
    });
    analyticsClient.trackWithContext('reset_button_clicked', {
      launchId,
    });
  };

  const done = () => {
    resetState();
    goToTab(Tab.PriorAuthInquiry);
    priorAuthLogger.info(`UHC smart app [${tab}] - Done button clicked`, {
      launchId,
      context: currentContext,
    });
    analyticsClient.trackWithContext('done_button_clicked', {
      launchId,
    });
  };

  const onConfirm = async (provider: Entities.Provider) => {
    const context = confirmSelectedProvider(provider);
    const updatedContext = await callUpdateEligibility(context);
    if (updatedContext) {
      launch(updatedContext);
    }
    setShouldSelectProvider(false);
  };

  return (
    <>
      <div
        style={
          currentTab !== tab || !shouldSelectProvider || !shouldRelaunch ? { display: 'none' } : {}
        }
      >
        <SelectProvider
          isLoading={loading}
          onConfirm={onConfirm}
          setSelectedProvider={setSelectedProvider}
          selectedProvider={selectedProvider}
          currentTab={currentTab}
        />
      </div>
      <div style={currentTab !== tab || shouldSelectProvider ? { display: 'none' } : {}}>
        {loading ? (
          <Loader size="default" type="dots" />
        ) : displayError ? (
          <ErrorScreen onCancel={cancel} onResubmit={resubmit} />
        ) : (
          <>
            <iframe title="submit-case-iframe" src={iframeUrl} className="uhc-iframe-wrapper" />
            {flowSuccess ? (
              <ActionButton text="Done" onClick={done} />
            ) : (
              <ActionButton text="Reset" onClick={reset} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UhcSmartAppWrapper;
