import { Insurer } from '@getvim-os/standard';
import { Tab } from '../../types';
import UhcSmartAppWrapper from '../insurer/uhc/UhcSmartAppWrapper';
import PriorAuthInquiry from '../prior-auth-inquiry';

export const componentByInsurer = {
  [Insurer.UHC]: {
    [Tab.PriorAuthInquiry]: PriorAuthInquiry,
    [Tab.SubmitCase]: UhcSmartAppWrapper,
    [Tab.StatusCheck]: UhcSmartAppWrapper,
  },
};
