import React, { FC } from 'react';
import { Button, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import './proceduresList.less';
import { PriorAuthQueryPayload, SelectOption } from '../../../types';
import { SetQueryPayloadFunction } from '../../../types/queryPayload';

interface ProcedureItemProps {
  text: string;
  onRemove: () => void;
}

const ProcedureItem: FC<ProcedureItemProps> = ({ text, onRemove }: ProcedureItemProps) => (
  <>
    <div className="procedure-item">
      <Button buttonType="link" className="icon-x-2 remove-btn" onClick={() => onRemove()} />
      <Text
        className="item-text"
        text={text}
        colorName={ColorNameEnum.ultraDark}
        weight={WeightEnum.medium}
        size={SizeEnum['12px']}
        inline
      />
    </div>
    <br />
  </>
);

interface ProceduresListProps {
  procedures: SelectOption[];
  setQueryPayload: SetQueryPayloadFunction;
  disabled: boolean;
}

const ProceduresList: FC<ProceduresListProps> = ({
  procedures,
  setQueryPayload,
  disabled,
}: ProceduresListProps) => {
  const onProcedureRemove = (value: string) => {
    if (disabled) return;

    const newProcedureList = procedures.filter((procedure) => procedure.value !== value);
    setQueryPayload((prevState: PriorAuthQueryPayload) => {
      return { ...prevState, procedures: newProcedureList };
    });
  };

  return (
    <div className="procedures-list">
      {procedures.map((procedure) => (
        <ProcedureItem
          text={`${procedure.value} - ${procedure.label}`}
          key={procedure.value}
          onRemove={() => onProcedureRemove(procedure.value)}
        />
      ))}
    </div>
  );
};

export default ProceduresList;
