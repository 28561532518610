import hash from 'object-hash';
import { PriorAuthQueryPayload, Patient } from '../types';

interface SearchSessionPayload {
  priorAuthQueryPayload: PriorAuthQueryPayload['procedures'];
  patientId: Patient['vimPatientId'];
  day: string;
}

class SearchSessionIdManager {
  private sessionId: string | undefined;

  public getSessionId(): string | undefined {
    return this.sessionId;
  }

  private updateSessionId(payload: SearchSessionPayload): string {
    const id = hash.MD5(payload);
    this.sessionId = id;
    return id;
  }

  public handleShouldUpdate(payload: Omit<SearchSessionPayload, 'day'>): void {
    const day = new Date().toLocaleDateString();
    this.updateSessionId({ ...payload, day });
  }
}

export const searchSessionIdManager = new SearchSessionIdManager();
