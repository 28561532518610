import React, { FC, useMemo, useState } from 'react';
import {
  AlignEnum,
  ColorNameEnum,
  InputStyle,
  InputStyleWrapper,
  Select,
  SizeEnum,
  Text,
  WeightEnum,
} from '@getvim/atomic-ui';
import { Entities } from '@getvim/vim-connect';
import { sendSyncEventToVimConnectHubApp } from '@getvim/utils-vim-connect-communication';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { Team } from '@getvim/feature-flags';
import { startCase, debounce } from 'lodash-es';
import LRU from 'lru-cache';
import { priorAuthLogger } from '../../logger';
import { Tab, WidgetOutgoingEventName } from '../../types';
import {
  NO_RESULTS,
  START_SEARCH_TWO_CHARS,
  SUBMIT_PROVIDER_ERROR,
} from '../../consts/selectInputMessages';
import './searchProviders.less';
import { searchMockedProviders } from '../../mocks/mock/searchMockedProviders';
import { widgetId } from '../../consts';

type ProviderSelectOption = {
  label: string;
  value: Entities.Provider;
};

const formatOptions = (providers: Entities.Provider[]): ProviderSelectOption[] => {
  return providers.map((provider) => {
    const label = [provider.demographics.lastName, provider.demographics.firstName]
      .map((n) => startCase(n.toLowerCase()))
      .join(', ');
    return {
      value: provider,
      label,
    };
  });
};

interface SearchProvidersProps {
  selectedProvider: Entities.Provider | undefined;
  setSelectedProvider: (providers: Entities.Provider | undefined) => void;
  currentTab: Tab;
  isDisabled: boolean;
}

const cacheTTL = 15 * 1000 * 60; // 15 minutes
const searchResultsCache = new LRU({ max: 500, ttl: cacheTTL });

const tabContent = {
  [Tab.SubmitCase]: {
    title: 'Submitting provider', // todo: replace with "Search provider"
    description: 'Select the submitting provider for this prior authorization',
  },
  [Tab.StatusCheck]: {
    title: 'Provider', // todo: replace with "Search provider"
    description: 'Select the submitting, ordering or servicing provider of the prior authorization',
  },
};

const SearchProviders: FC<SearchProvidersProps> = ({
  setSelectedProvider,
  selectedProvider,
  currentTab,
  isDisabled = false,
}: SearchProvidersProps) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Entities.Provider[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSearch = async (searchTerm: string) => {
    try {
      const cachedResults = searchResultsCache.get(searchTerm);
      if (cachedResults) {
        const results = cachedResults as Entities.Provider[];
        priorAuthLogger.info(`searching "${searchTerm}" - fetching results from local cache`, {
          results,
        });
        setSearchResults(results);
      }

      // uncomment the following line to use mocked results:
      // const results = searchMockedProviders(searchTerm);
      const results = await sendSearchToHubApp(searchTerm);
      searchResultsCache.set(searchTerm, results);
      setSearchResults(results);
    } catch (error: any) {
      priorAuthLogger.error(
        `error when searching "${searchTerm}" - cannot send search query to the hub`,
        { error },
      );
      setSearchResults([]);
    }
    setIsLoading(false);
  };

  const sendSearchToHubApp = async (term: string): Promise<Entities.Provider[]> => {
    const payload = { term };
    priorAuthLogger.info(`searching "${term}" - sending SearchProvidersQuery event to hub...`, {
      payload,
    });
    const results = await sendSyncEventToVimConnectHubApp(
      widgetId,
      WidgetOutgoingEventName.SearchProvidersQuery,
      payload,
    );
    priorAuthLogger.info(`searching "${term}" - got results from hub`, { results });
    return results;
  };

  const onProvidersChange = (items: ProviderSelectOption[]) => {
    if (items.length) {
      const selectedItem = items[0].value;
      // add this condition, otherwise the provider can be set to undefined its being selected
      if (selectedItem) {
        setSelectedProvider(selectedItem);
        setSearchResults(undefined);
      }
    } else {
      setSelectedProvider(undefined);
      setIsTouched(true);
    }
    if (!selectedProvider) {
      setSearchResults(undefined);
    }
  };

  const selectedValue = useMemo(() => {
    if (selectedProvider) return formatOptions([selectedProvider]);

    return [];
  }, [selectedProvider]);

  const onSearchInputChanged = (args) => {
    const searchTerm = args.state.search;

    if (searchTerm?.length < 2) {
      setSearchResults(undefined);
      return undefined;
    }

    setIsLoading(true);
    debounce((bounchSearchTerm) => {
      onSearch(bounchSearchTerm);
    }, 500)(searchTerm);
    return formatOptions(searchResults ?? []) as any;
  };

  return (
    <div className="search-providers margin-bottom-20">
      <div>
        <Text
          text="Search provider"
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.bold}
          size={SizeEnum['14px']}
          className="search-providers__title"
        />
        <Text
          text={tabContent[currentTab]?.description}
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.normal}
          size={SizeEnum['12px']}
          className="search-providers__description"
        />
      </div>

      <div>
        <InputStyleWrapper
          leftIcon={<i className="icon-search" />}
          inputStyle={InputStyle.medium}
          className="search-provider-input-wrapper input-wrapper "
        >
          <div className="input">
            <Select
              label="Provider"
              id={`providers-select-${currentTab}`}
              name="providers"
              options={formatOptions(searchResults ?? [])}
              values={selectedValue}
              onChange={(items) => {
                onProvidersChange(items);
              }}
              searchFn={onSearchInputChanged}
              noDataLabel={searchResults ? NO_RESULTS : START_SEARCH_TWO_CHARS}
              clearable={!!selectedProvider}
              isDisabled={isDisabled}
              loading={isLoading}
              hideValueOnInput
              noResultsLabel={NO_RESULTS}
              noInputLabel={START_SEARCH_TWO_CHARS}
              showChevronIcon={!selectedProvider}
            />
          </div>
        </InputStyleWrapper>
      </div>
      {isTouched && !selectedProvider && (
        <div className="margin-top-5 error-message">
          <Text
            text={SUBMIT_PROVIDER_ERROR}
            weight={WeightEnum.medium}
            size={SizeEnum['12px']}
            align={AlignEnum.right}
          />
        </div>
      )}
    </div>
  );
};

export default SearchProviders;
