import { SMARTApp } from './smartOnFHIR';

export enum Tab {
  PriorAuthInquiry = 'PA inquiry',
  SubmitCase = 'Submit case',
  StatusCheck = 'Status check',
}

export const tabToSmartApp = {
  [Tab.SubmitCase]: SMARTApp.PACS,
  [Tab.StatusCheck]: SMARTApp.PACSU,
};

export enum RedirectOrigin {
  APP_TAB = 'app_tab',
  PA_SUBMIT_BUTTON = 'PA_submit_button',
  PA_HYPERLINK = 'PA_hyperlink',
}
