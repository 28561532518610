import React from 'react';
import { Button } from '@getvim/atomic-ui';
import errorIconSvg from '../../assets/error-icon.svg';
import unitedHealthcareIconSvg from '../../assets/united-healthcare.svg';
import './errorScreen.less';

interface ErrorScreenProps {
  onCancel: () => void;
  onResubmit: () => void;
}

export const ErrorScreen: React.FC<ErrorScreenProps> = ({
  onCancel,
  onResubmit,
}: ErrorScreenProps) => (
  <div>
    <img src={unitedHealthcareIconSvg} alt="insurer-logo" className="insurer-logo" />
    <div className="error-message-container">
      <img src={errorIconSvg} alt="error" className="error-icon" />
      <div className="error-message-first-line">We&apos;re Sorry,</div>
      <div className="error-message-second-line">something went wrong.</div>
    </div>

    <div className="error-screen-buttons-container">
      <Button
        className="error-screen-cancel-button"
        text="Cancel"
        buttonType="tiny"
        bgColor={null}
        onClick={onCancel}
      />
      <Button
        className="error-screen-resubmit-button"
        text="Try again"
        buttonType="tiny"
        bgColor="default"
        onClick={onResubmit}
      />
    </div>
  </div>
);
