import React, { FC } from 'react';
import { ColorNameEnum, SizeEnum, WeightEnum, Text, AlignEnum } from '@getvim/atomic-ui';
import SiteOfServiceItem from './SiteOfService';
import { Procedure as ProcedureType, SiteOfService, DecisionCode } from '../../../types';

interface ProcedureProps {
  procedure: ProcedureType;
  count: number;
  state: string;
  productType: string;
}

const mapSitesOfServiceToClass = (items: SiteOfService[]): string => {
  if (items.every((x) => x.code === DecisionCode.Required)) return 'required';
  if (items.every((x) => x.code === DecisionCode.Blocked)) return 'required';
  if (items.every((x) => x.code === DecisionCode.NotRequired)) return 'notRequired';
  return 'conditionally';
};

const Procedure: FC<ProcedureProps> = ({
  procedure,
  state,
  productType,
  count,
}: ProcedureProps) => {
  return (
    <div>
      <div className="margin-top">
        <Text
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.normal}
          size={SizeEnum['12px']}
          align={AlignEnum.left}
          inline
        >
          <strong>{procedure.code}</strong>&nbsp;- {procedure.description}
        </Text>
      </div>
      <div className="margin-top">
        <Text
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.normal}
          size={SizeEnum['12px']}
          inline
        >
          <strong>Product type:</strong>&nbsp; {productType} &nbsp;|&nbsp;
          <strong>State:</strong>&nbsp; {state}
        </Text>
      </div>
      <div className={`summary ${mapSitesOfServiceToClass(procedure.sitesOfService)}`}>
        <Text
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.bold}
          size={SizeEnum['14px']}
          inline
        >
          {procedure.decisionSummary}
        </Text>
      </div>
      {procedure.sitesOfService.map((site) => (
        <SiteOfServiceItem site={site} key={site.name} />
      ))}
      {count > 1 && <div className="divider" />}
    </div>
  );
};

export default Procedure;
