import React, { FC, useCallback } from 'react';
import { uniqBy } from 'lodash-es';
import {
  AlignEnum,
  ColorNameEnum,
  InputStyle,
  InputStyleWrapper,
  SizeEnum,
  Text,
  WeightEnum,
  Select,
} from '@getvim/atomic-ui';
import { formatOptions } from './formatOptions';
import {
  FreeTextQueryPayload,
  FreeTextResult,
  FreeTextType,
  PriorAuthQueryPayload,
  SelectOption,
} from '../../../types';
import { queryAutocompleteOverload } from './queryAutocompleteOptions';
import { ERROR, NO_RESULTS, START_SEARCH } from '../../../consts/selectInputMessages';
import './searchProcedures.less';
import { SetQueryPayloadFunction } from '../../../types/queryPayload';

interface SearchProceduresProps {
  procedures: SelectOption[];
  setQueryPayload: SetQueryPayloadFunction;
  onFreeTextQuery: (payload: FreeTextQueryPayload) => void;
  cptFreeTextOptions: FreeTextResult[] | undefined;
  resetFreeTextOptions: (type: FreeTextType) => void;
  disabled: boolean;
  loading: boolean;
  error: boolean;
}

const SearchProcedures: FC<SearchProceduresProps> = ({
  procedures,
  setQueryPayload,
  onFreeTextQuery,
  cptFreeTextOptions,
  resetFreeTextOptions,
  disabled,
  loading,
  error,
}: SearchProceduresProps) => {
  const queryAutocompleteOptions = queryAutocompleteOverload(onFreeTextQuery);
  const reachedOptionsLimit = procedures.length >= 5;

  const onProceduresChange = useCallback(
    (items: SelectOption[]) => {
      // Don't allow to add more than 5 options
      if (reachedOptionsLimit) return;

      const [selectedItem] = items ?? [];

      if (selectedItem) {
        // Don't allow to add the same option twice
        const newProceduresList = uniqBy([...procedures, selectedItem], 'value');
        setQueryPayload((prevState: PriorAuthQueryPayload) => {
          return { ...prevState, procedures: newProceduresList };
        });
      }

      resetFreeTextOptions(FreeTextType.Cpt);
    },
    [reachedOptionsLimit, procedures, resetFreeTextOptions, setQueryPayload],
  );

  const onSearchInputChanged = ({ state, methods }) => {
    queryAutocompleteOptions(methods.safeString(state.search), 'input-change', FreeTextType.Cpt);
  };

  const getNoDataLabel = (): string => {
    if (error) {
      return ERROR;
    }
    return cptFreeTextOptions === undefined ? START_SEARCH : NO_RESULTS;
  };

  const getFormattedOptions = () => {
    return formatOptions(cptFreeTextOptions ?? [], procedures, reachedOptionsLimit);
  };

  return (
    <div>
      <div>
        <Text
          text="Enter procedure code"
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum.medium}
          size={SizeEnum['14px']}
          inline
        />
        &nbsp;
        <Text text="(up to 5 codes)" weight={WeightEnum.medium} size={SizeEnum['14px']} inline />
      </div>

      <div className="margin-top-10">
        <InputStyleWrapper
          leftIcon={<i className="icon-search" />}
          inputStyle={InputStyle.medium}
          disabled={disabled}
        >
          <div className="input">
            <Select
              id="cpt-select"
              className="select-cpt-selectbox"
              name="procedures"
              options={getFormattedOptions()}
              onChange={(items) => {
                onProceduresChange(items);
              }}
              searchFn={onSearchInputChanged}
              noDataLabel={getNoDataLabel()}
              loading={loading}
              clearable={false}
              hideValueOnInput
              error={error ? ERROR : undefined}
              noResultsLabel={NO_RESULTS}
              noInputLabel={START_SEARCH}
              labelField="displayLabel"
            />
          </div>
        </InputStyleWrapper>
        <Text
          text="* Medical services only"
          weight={WeightEnum.medium}
          size={SizeEnum['12px']}
          align={AlignEnum.left}
          className="margin-top-10"
        />
      </div>
    </div>
  );
};

export default SearchProcedures;
