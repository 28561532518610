import { FreeTextResult, SelectOption } from '../../../types';

export const formatOptions = (
  options: FreeTextResult[],
  optionsToOmit: SelectOption[] = [],
  disabled?: boolean,
): SelectOption[] =>
  options
    .map((option) => ({
      value: option.taxonomyCode,
      label: option.taxonomyDisplayName,
      displayLabel: `${option.taxonomyCode} - ${option.taxonomyDisplayName}`,
      disabled,
    }))
    .filter(
      (option) =>
        !option.value.includes('_') && optionsToOmit.every((x) => x.value !== option.value),
    );
