import React, { useEffect } from 'react';
import { WIDGET_DEFAULT_WIDTH } from '../../consts/widgetSize';
import { Tab } from '../../types';
import PriorAuthResult, { PriorAuthResultProps } from './prior-auth-result/PriorAuthResult';
import PriorAuthSearch, { SearchProcedureProps } from './prior-auth-search/PriorAuthSearch';

export type PriorAuthInquiryProps = SearchProcedureProps &
  PriorAuthResultProps & { currentTab: Tab; changeTabWidth: (width: string) => void };

const PriorAuthInquiry: React.FC<PriorAuthInquiryProps> = (props) => {
  const { priorAuthResult, currentTab, changeTabWidth } = props as PriorAuthInquiryProps;
  const shouldHide = currentTab !== Tab.PriorAuthInquiry;

  useEffect(() => {
    if (shouldHide) return;
    changeTabWidth(WIDGET_DEFAULT_WIDTH);
  }, [changeTabWidth, currentTab, shouldHide]);

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div style={shouldHide ? { display: 'none' } : {}}>
      {priorAuthResult ? <PriorAuthResult {...props} /> : <PriorAuthSearch {...props} />}
    </div>
  );
};

export default PriorAuthInquiry;
