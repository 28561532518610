import React, { FC, useCallback, useMemo } from 'react';
import { Button, ColorNameEnum, Banner, BannerTypes } from '@getvim/atomic-ui';
import './priorAuthSearch.less';
import {
  FreeTextQueryPayload,
  FreeTextResult,
  FreeTextType,
  PriorAuthQueryPayload,
  RedirectOrigin,
  Tab,
} from '../../../types';
import ProceduresList from './ProceduresList';
import SearchProcedures from './SearchProcedures';
import SearchDiagnosis from './SearchDiagnosis';
import { analyticsClient } from '../../../analytics/analytics';
import { SetQueryPayload } from '../../../types/queryPayload';

export interface SearchProcedureProps {
  onFreeTextQuery: (payload: FreeTextQueryPayload) => void;
  onSearchClick: (payload: PriorAuthQueryPayload) => void;
  cptFreeTextOptions: FreeTextResult[] | undefined;
  cptQueryLoading: boolean;
  cptQueryError: boolean;
  icdFreeTextOptions: FreeTextResult[] | undefined;
  icdQueryLoading: boolean;
  icdQueryError: boolean;
  resetFreeTextOptions: (type: FreeTextType) => void;
  isSearchInProgress: boolean;
  setIsSearchInProgress: (isInProgress: boolean) => void;
  submitPriorAuth: (skipFormValidation?: boolean) => void;
  useTabs: boolean;
  queryPayload?: PriorAuthQueryPayload;
  setQueryPayload: SetQueryPayload;
}

const PriorAuthSearch: FC<SearchProcedureProps> = ({
  onFreeTextQuery,
  onSearchClick,
  cptFreeTextOptions,
  cptQueryLoading,
  cptQueryError,
  icdFreeTextOptions,
  icdQueryLoading,
  icdQueryError,
  resetFreeTextOptions,
  isSearchInProgress,
  setIsSearchInProgress,
  useTabs,
  submitPriorAuth,
  queryPayload,
  setQueryPayload,
}: SearchProcedureProps) => {
  const isButtonDisabled = useMemo(() => {
    const { procedures } = queryPayload ?? {};
    return procedures && (procedures.length < 1 || procedures.length > 5);
  }, [queryPayload]);

  const onClick = useCallback(() => {
    const { procedures, diagnosis } = queryPayload ?? {};

    if (procedures && procedures?.length > 0) {
      setIsSearchInProgress(true);
      onSearchClick({ procedures, diagnosis });
    }
  }, [queryPayload, onSearchClick, setIsSearchInProgress]);

  return (
    <div id="pa-inquiry-form">
      {useTabs ? (
        <Banner
          icon={<span className="icon-info" />}
          text={
            <>
              Searching by code below will not render a Decision ID.
              {' If one is required, please click on '}
              <Button
                color="ultraDark"
                buttonType="link"
                className="text-ultra-dark text-underline"
                onClick={() => {
                  submitPriorAuth(true);
                  analyticsClient.track('prior_auth_tab_switch', {
                    tabName: Tab.SubmitCase,
                    redirected_from: RedirectOrigin.PA_HYPERLINK,
                  });
                }}
              >
                Submit Case
              </Button>
            </>
          }
          type={BannerTypes.WARNING}
          textColor={ColorNameEnum.ultraDark}
        />
      ) : undefined}
      <div className="prior-auth-search">
        <SearchProcedures
          procedures={queryPayload?.procedures ?? []}
          setQueryPayload={setQueryPayload}
          onFreeTextQuery={onFreeTextQuery}
          cptFreeTextOptions={cptFreeTextOptions}
          resetFreeTextOptions={resetFreeTextOptions}
          disabled={isSearchInProgress}
          loading={cptQueryLoading}
          error={cptQueryError}
        />

        <ProceduresList
          procedures={queryPayload?.procedures ?? []}
          setQueryPayload={setQueryPayload}
          disabled={isSearchInProgress}
        />

        <SearchDiagnosis
          setQueryPayload={setQueryPayload}
          diagnosis={queryPayload?.diagnosis}
          onFreeTextQuery={onFreeTextQuery}
          icdFreeTextOptions={icdFreeTextOptions}
          resetFreeTextOptions={resetFreeTextOptions}
          disabled={isSearchInProgress}
          loading={icdQueryLoading}
          error={icdQueryError}
        />

        <Button
          disabled={isButtonDisabled}
          text="Search"
          buttonType="small"
          width="block"
          onClick={onClick}
          isLoading={isSearchInProgress}
        />
      </div>
    </div>
  );
};

export default PriorAuthSearch;
