export enum SMARTApp {
  PACS = 'PACS',
  PACSU = 'PACSU',
}

export interface LaunchMetadata {
  smartAppUrl: string;
}

export enum SMARTAppStatus {
  Success = 'Success',
  Failure = 'Failure',
}
