export enum WidgetIncomingEventName {
  PatientInContext = 'PatientInContext',
  PatientOutOfContext = 'PatientOutOfContext',
  EncounterStart = 'EncounterStart',
  OrderCreated = 'OrderCreated',
  OrderViewed = 'OrderViewed',
  OrderClosed = 'OrderClosed',
  FreeTextQueryResult = 'FreeTextQueryResult',
  SearchPriorAuthResult = 'SearchPriorAuthResult',
  ReceivePriorAuthAnalytics = 'ReceivePriorAuthAnalytics',
  ProviderInContext = 'ProviderInContext',
  PopToast = 'PopToast',
  GetProductConfig = 'GetProductConfig',
  NotificationClosed = 'notification-on-close',
}

export enum WidgetOutgoingEventName {
  FreeTextQuery = 'FreeTextQuery',
  SearchPriorAuth = 'SearchPriorAuth',
  LaunchSmartApp = 'LaunchSmartApp',
  UpdateEligibility = 'UpdateEligibility',
  ModifyWidgetSize = 'ModifyWidgetSize',
  SearchProvidersQuery = 'SearchProvidersQuery',
}
